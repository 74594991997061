<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>

    <el-row class="content-box">
      <div class="sec-title">商品统计</div>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="商品标题">
          <el-input v-model="title" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="status">
            <el-option label="已通过" :value="2"></el-option>
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="审核未通过" :value="3"></el-option>
            <el-option label="注销" :value="4"></el-option>
            <el-option label="已冻结" :value="5"></el-option>
            <el-option label="未启用" :value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="search()">查询</el-button>
          <el-button
            @click="addDialog = true"
            type="primary"
            style="float: right"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 
      <el-row class="main" style="margin-bottom: 1rem">
        <label for="teacher_name">商品标题</label>
        <el-input v-model="title" clearable></el-input>

        <label for="mechanism_name">所属机构</label>
        <el-select filterable v-model="mechanism_id" clearable remote :remote-method="searchOrgName"
          @focus="searchOrgName">
          <el-option v-for="(option, index) in mechanism_name_list" :key="index" :value="option.id"
            :label="option.mechanism_name"></el-option>
        </el-select>
        <label for="mechanism_name">状态</label>
        <el-select v-model="status">
          <el-option label="已通过" :value="2"></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="审核未通过" :value="3"></el-option>
          <el-option label="注销" :value="4"></el-option>
          <el-option label="已冻结" :value="5"></el-option>
          <el-option label="未启用" :value="6"></el-option>
        </el-select>
        <el-button @click="search()">查询</el-button>
        <el-button @click="addDialog = true" type="primary" style="float: right"
          >新增</el-button
        >
      </el-row> -->
      <el-table :data="goodList" v-loading="isLoading">
        <el-table-column label="商品id" prop="id"> </el-table-column>
        <el-table-column label="商品标题" prop="title"> </el-table-column>
        <el-table-column label="商品主类目" prop="categories">
        </el-table-column>
        <el-table-column label="商品分类目" prop="categories_child">
        </el-table-column>
        <el-table-column label="最低点数" prop="premium_point">
        </el-table-column>
        <el-table-column label="最低价格" prop="amout"> </el-table-column>
        <!-- <el-table-column label="适用年龄" prop="apply_age"> </el-table-column> -->
        <el-table-column
          label="商品购买数"
          width="100"
          prop="pay_num"
        ></el-table-column>
        <!-- <el-table-column label="商品通用价格" width="120" prop="amout">
        </el-table-column> -->
        <!-- todo -->
        <el-table-column
          label="上架时间"
          width="200"
          prop="create_time"
        ></el-table-column>
        <!-- <el-table-column label="卡类型" prop="card_type"> </el-table-column> -->
        <!-- <el-table-column label="权益点数" prop="pay_point"></el-table-column> -->
        <el-table-column label="是否推荐" prop="is_recommend">
          <template slot-scope="scope">
            {{ scope.row.is_recommend ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1" style="color: orange"
              >正在审核</span
            >
            <span v-if="scope.row.status == 2" style="color: green"
              >已通过</span
            >
            <span v-if="scope.row.status == 3" style="color: red"
              >审核拒绝</span
            >
            <span v-if="scope.row.status == 4" style="color: red">注销</span>
            <span v-if="scope.row.status == 5" style="color: red">冻结</span>
            <span v-if="scope.row.status == 6">未启用</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="" width="480">
          <template slot-scope="scope">
            <div style="display: flex">
              <!-- <el-button size="small">下架</el-button> -->
              <!-- <el-button size="small" type="primary" @click="toGoodDetails(scope.row)">查看详情</el-button> -->
              <el-button
                size="small"
                type="primary"
                @click="openUpdate(scope.row)"
                >编辑</el-button
              >
              <el-button
                size="small"
                type="success"
                @click="openUpdateInfo(scope.row)"
                >编辑商品信息</el-button
              >
              <el-button
                size="small"
                type="warning"
                @click="openUpdatePrice(scope.row)"
                >编辑商品价格</el-button
              >
              <el-button
                size="small"
                type="danger"
                v-if="scope.row.status == 2"
                @click="frozen(scope.row)"
                >冻结
              </el-button>

              <el-button
                size="small"
                type="success"
                v-if="scope.row.status == 5"
                @click="unfrozen(scope.row)"
                >解冻
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <paging-fy
        @currentPageChange="currentPageChange"
        :currentPage="currentPage"
        :total="goodTotal"
      ></paging-fy>
    </el-row>
    <!-- 新增商品 -->
    <el-dialog
      :width="ks ? '95%' : '50%'"
      :visible="addDialog || updateDialog"
      :title="addDialog ? '新增商品' : updateDialog ? '修改商品' : ''"
      :before-close="handleClose"
    >
      <el-form
        v-model="form"
        label-width="auto"
        style="display: flex; justify-content: space-around; flex-wrap: wrap"
      >
        <div :style="ks ? 'width:100%' : 'width: 50%'">
          <el-form-item label="商品名称">
            <el-input v-model="form.title" :placeholder="temp.title"></el-input>
          </el-form-item>
          <el-form-item label="商品主类目">
            <el-select v-model="form.categories" :placeholder="temp.categories">
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品类目">
            <el-select
              v-model="form.categories_child"
              :placeholder="temp.categories_child"
            >
              <el-option
                v-for="(item, index) in getCateChilds(form.categories)"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="商品价格">
            <el-input v-model="form.amout" :placeholder="temp.amout"></el-input><span>元</span>
          </el-form-item> -->
          <!-- <el-form-item label="是否推荐">
            <el-checkbox v-model="form.is_recommend"></el-checkbox>
          </el-form-item>
          <el-form-item label="是否打折">
            <el-checkbox v-model="form.is_discount"></el-checkbox>
          </el-form-item>

          <el-form-item label="折扣价" v-if="form.is_discount">
            <el-input v-model='form.discount_amout'></el-input>
            <span class="form_text">元</span>
          </el-form-item> -->
          <!-- <el-form-item label="团课人数">
            <el-input v-model="form.connect_peoplenum" :placeholder="temp.connect_peoplenum"></el-input><span>人</span>
          </el-form-item>
          <el-form-item label="课时数">
            <el-input v-model="form.course_num" :placeholder="temp.course_num"></el-input><span>节</span>
          </el-form-item>
          <el-form-item label="有效期">
            <el-input v-model="form.duration" :placeholder="temp.duration"></el-input><span>月</span>
          </el-form-item> -->
          <!-- <el-form-item label="所属机构">
            <el-select v-model="form.mechanism_id" :placeholder="mechanism_name(temp.mechanism_id).mechanism_name">
              <el-option v-for="(item, index) in mechanism_name_list" :key="index" :label="item.mechanism_name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item> -->
        </div>
        <div :style="ks ? 'width: 100%' : 'width: 50%'">
          <!-- <el-form-item label="适用年龄">
            <el-select v-model="form.apply_age" :placeholder="temp.apply_age">
              <el-option v-for="(item, index) in age_type_list" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教龄">
            <el-select v-model="form.school_age" :placeholder="temp.school_age">
              <el-option v-for="(item, index) in school_age_list" :key="index" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="会员卡">
            <el-cascader v-model="card_type" :options="cardLevel" remote @focus="getCardLevel" :props="{
                children: 'childrens',
                label: 'member_name',
                value: 'member_name',
                multiple: true,
              }" :show-all-levels="false" @change="getCardType" :placeholder="temp.card_type"></el-cascader>
          </el-form-item> -->
          <!-- <el-form-item label="权益点数">
            <el-input v-model="form.pay_point" :placeholder="temp.pay_point"></el-input>
          </el-form-item> -->
          <el-form-item label="封面">
            <avatar-uploader
              @getImgUrl="getCover"
              :url="temp.face_url"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="介绍">
            <el-input
              type="textarea"
              style="width: 50%"
              v-model="form.introduction_content"
              :placeholder="temp.introduction_content"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="商品介绍图片">
            <images-uploader @getImgUrl="getProductImg" :urls="temp.introduction_url" :clear="if_clear">
            </images-uploader>
          </el-form-item> -->
          <!-- <el-form-item label="商品标签">
            <el-input v-model="form.label" :placeholder="temp.label"></el-input>
          </el-form-item> -->
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="addDialog" @click="addNewgood()"
          >确 定</el-button
        >
        <el-button type="primary" v-if="updateDialog" @click="update()"
          >确 定</el-button
        >
        <el-button @click="handleClose()">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 修改商品信息 -->
    <el-dialog
      :width="ks ? '95%' : '50%'"
      :visible.sync="updateInfoDialog"
      :before-close="handleClose"
      title="修改商品信息"
    >
      <!--
       "master_set_price_id": 1,
        "type": "类别",
        "names": "名",
        "colors": "颜色 ，隔开",
        "specification": "规格 ，隔开",
        "addr": "发货地址",
        "brands": "品牌",
        "function": "功能",
        "is_mass_market": true,
        "net_content": "1ml",
        "courier_fee": 0 -->
      <el-form v-model="form" v-loading="updateLoading" label-width="auto">
        <el-form-item label="规格">
          <el-tag
            :key="tag"
            v-for="tag in specificationTags"
            closable
            :disable-transitions="false"
            @close="deleteTags(tag, 'specify')"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="specifyInputVisible"
            v-model="inputValue"
            ref="saveSpecifyInput"
            size="small"
            @keyup.enter.native="handleInputConfirm('specify')"
            @blur="handleInputBlur('specify')"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput('specify')"
            >+ New Tag</el-button
          >
        </el-form-item>
        <el-form-item label="颜色">
          <el-tag
            :key="tag"
            v-for="tag in colorTags"
            closable
            :disable-transitions="false"
            @close="deleteTags(tag, 'color')"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="colorInputVisible"
            v-model="inputValue"
            ref="saveColorInput"
            size="small"
            @keyup.enter.native="handleInputConfirm('color')"
            @blur="handleInputBlur('color')"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput('color')"
            >+ New Tag</el-button
          >
        </el-form-item>
        <el-form-item label="发货地址">
          <el-input v-model="form.addr" :placeholder="temp.addr"></el-input>
        </el-form-item>
        <el-form-item label="品牌">
          <el-input v-model="form.brands" :placeholder="temp.brands"></el-input>
        </el-form-item>
        <el-form-item label="功能">
          <el-input
            v-model="form.function"
            :placeholder="temp.function"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否量贩装">
          <el-checkbox v-model="form.is_mass_market"></el-checkbox>
        </el-form-item>
        <el-form-item label="净含量">
          <el-input
            v-model="form.net_content"
            :placeholder="temp.net_content"
          ></el-input>
        </el-form-item>
        <el-form-item label="快递费">
          <el-input
            v-model="form.courier_fee"
            :placeholder="temp.courier_fee"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" type="primary" @click="updateInfo()"
          >修改</el-button
        >
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <!-- 修改商品价格 -->
    <el-dialog
      :visible="updatePriceDialog"
      :before-close="handleClose"
      title="修改商品价格"
      fullscreen
    >
      <el-table :data="price_list" v-loading="priceLoading" stripe border>
        <el-table-column label="是否有此型号" prop="online"> </el-table-column>
        <el-table-column label="id" prop="id"> </el-table-column>
        <el-table-column label="颜色" prop="colors"></el-table-column>
        <el-table-column label="型号" prop="size"></el-table-column>
        <el-table-column label="点数" prop="prices">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form.prices"
              :placeholder="scope.row.prices"
              v-if="index == scope.$index"
            >
            </el-input>
            <span v-else>{{ scope.row.prices }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格" prop="amount">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form.amount"
              :placeholder="scope.row.amount"
              v-if="index == scope.$index"
            >
            </el-input>
            <span v-else>{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="库存" prop="inventory">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="form.inventory"
              :placeholder="scope.row.inventory"
              v-if="index == scope.$index"
            ></el-input>
            <span v-else>{{ scope.row.inventory }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="" width="280">
          <template slot-scope="scope">
            <el-button
              type="warning"
              size="small"
              v-if="index == scope.$index"
              @click="closeEdit(scope.row)"
              >关闭编辑
            </el-button>
            <el-button type="primary" size="small" v-else @click="edit(scope)"
              >编辑</el-button
            >
            <el-button
              type="success"
              size="small"
              v-if="!(index === null) && index == scope.$index"
              @click="save(scope.row)"
              >保存
            </el-button>
            <el-button
              type="danger"
              size="small"
              v-if="scope.row.id && scope.row.status == 2"
              @click="deleteItem(scope.row, 5)"
              >冻结</el-button
            >
            <el-button
              type="success"
              size="small"
              v-if="scope.row.id && scope.row.status == 5"
              @click="deleteItem(scope.row, 2)"
              >启用</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button type="danger" @click="handleClose">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ks: "",
      nav: {
        firstNav: "电商管理中心",
        secondNav: "商品管理",
      },
      isLoading: true,
      if_clear: false,
      // 商品列表
      currentPage: 1,
      goodTotal: null,
      updateInfoDialog: false,
      updatePriceDialog: false,
      goodList: [],
      // 搜索
      mechanism_id: null,
      status: null,
      mechanism_name_list: [],
      categories_child_list: [],
      // master_id: null,
      // master_name_list: [],
      title: null,
      master_name: "",
      cardLevel: [],
      // allCardLevel: [],
      // 商品类型图表
      goodType: [],

      temp: {},
      form: {},
      addDialog: false,
      card_type: [],
      updateDialog: false,
      updateLoading: false,
      age_type_list: [],
      school_age_list: [],
      colorTags: ["红色", "黑色", "白色"],
      specificationTags: ["大", "小", "中"],
      colorInputVisible: false,
      specifyInputVisible: false,
      inputValue: "",
      goodInfo: {},
      price_list: [],
      priceLoading: true,
      type_list: [],
      index: null,
    };
  },
  methods: {
    // 修改商品信息
    // 添加颜色
    deleteTags(tag, target) {
      if (target == "color") {
        this.colorTags.splice(this.colorTags.indexOf(tag), 1);
      } else if (target == "specify") {
        this.specificationTags.splice(this.specificationTags.indexOf(tag), 1);
      }
    },

    showInput(target) {
      if (target == "color") {
        this.colorInputVisible = true;
        this.$nextTick((_) => {
          this.$refs.saveColorInput.$refs.input.focus();
        });
      } else if (target == "specify") {
        this.specifyInputVisible = true;
        this.$nextTick((_) => {
          this.$refs.saveSpecifyInput.$refs.input.focus();
        });
      }
    },

    handleInputConfirm(target) {
      let inputValue = this.inputValue;
      if (inputValue && target == "color") {
        this.colorTags.push(inputValue);
      } else if (inputValue && target == "specify") {
        this.specificationTags.push(inputValue);
      }
      this.inputValue = "";
    },
    handleInputBlur(target) {
      let inputValue = this.inputValue;
      if (inputValue && target == "color") {
        this.colorTags.push(inputValue);
      } else if (inputValue && target == "specify") {
        this.specificationTags.push(inputValue);
      }
      this.colorInputVisible = false;
      this.specifyInputVisible = false;
      this.inputValue = "";
    },

    openUpdateInfo(item) {
      this.updateLoading = true;
      this.goodInfo = item;
      let url = "/user/masterSetPriceInfo/queryListPage";
      this.$axios
        .get(url, {
          params: { master_set_price_id: item.id, status: 2 },
        })
        .then((res) => {
          if (res.data.data.length) {
            this.updateLoading = false;
            this.temp = res.data.data[0];
            this.colorTags = this.temp.colors.split(",");
            this.specificationTags = this.temp.specification.split(",");
            this.updateInfoDialog = true;
          } else {
            this.updateLoading = false;
            this.updateInfoDialog = true;
          }
        });
    },
    updateInfo() {
      let data = { ...this.form };
      data.master_set_price_id = this.goodInfo.id;
      let url;
      if (this.temp.id) {
        url = "/user/masterSetPriceInfo/update";
        data.id = this.temp.id;
      } else {
        url = "/user/masterSetPriceInfo/insertPriceInfo";
      }
      data.colors =
        this.colorTags.join(",") != this.temp.colors
          ? new Set(this.colorTags).join(",")
          : null;
      data.specification =
        this.specificationTags.join(",") != this.temp.specification
          ? new Set(this.specificationTags).join(",")
          : null;
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    // 结束修改商品信息
    //修改商品价格
    getPriceList(id) {
      let url1 = "/user/masterSetPriceSize/query";
      this.price_list = [];
      this.priceLoading = true;
      this.$axios
        .get(url1, {
          params: { master_set_price_id: id },
        })
        .then((res) => {
          this.priceLoading = false;
          // console.log(this.colorTags,this.specificationTags);
          for (let colorIndex in this.colorTags) {
            for (let specifyIndex in this.specificationTags) {
              // console.log(this.specificationTags[specifyIndex]);
              this.price_list.push({
                colors: this.colorTags[colorIndex],
                size: this.specificationTags[specifyIndex],
                amount: null,
                prices: null,
                minPrice: null,
                minAmount: null,
                inventory: null,
              });
            }
          }
          let temp = res.data.data;
          this.price_list.forEach((type, local_index, local_arr) => {
            res.data.data.forEach((item, index, arr) => {
              item.online = "是";
              console.log(type, item);
              if (type.colors == item.colors && type.size == item.size) {
                this.$set(local_arr, local_index, item);
                temp.splice(index, 1);
              }
            });
          });
          this.price_list = [...this.price_list, ...temp];
          this.priceLoading = false;
          console.log(this.price_list);
          this.$forceUpdate();
          this.updatePriceDialog = true;
        });
    },
    openUpdatePrice(item) {
      this.priceLoading = true;
      this.goodInfo = item;
      let url2 = "/user/masterSetPriceInfo/queryListPage";
      this.$axios
        .get(url2, {
          params: { master_set_price_id: item.id, status: 2 },
        })
        .then((res) => {
          if (res.data.data.length) {
            this.colorTags = res.data.data[0].colors.split(",");
            this.specificationTags = res.data.data[0].specification.split(",");
            this.getPriceList(item.id);
          } else {
            this.$message({
              type: "error",
              message: "未查到商品信息!请先完善商品信息!",
            });
          }
        });
    },
    edit(scope) {
      console.log(scope);
      this.index = scope.$index;
    },
    save(row) {
      let data = { ...this.form };
      data.colors = row.colors;
      data.size = row.size;
      let a = this.price_list.map((item) => {
        if (item.amount) {
          return item.amount;
        }
      });
      let minAmount = Math.min.apply(
        Math,
        a.filter((item) => {
          return item !== undefined;
        })
      );
      console.log("a", minAmount);

      let p = this.price_list.map((item) => {
        if (item.prices) {
          return item.prices;
        }
      });
      let minPrice = Math.min.apply(
        Math,
        p.filter((item) => {
          return item !== undefined;
        })
      );
      console.log("p", minPrice);

      // console.log(this.goodInfo)
      // console.log(this.price_list)
      // console.log(minPrice, minAmount)
      data.minPrice = data.prices ? Math.min(minPrice, data.prices) : null;

      data.minAmount = data.amount ? Math.min(minAmount, data.amount) : null;

      let url;
      if (row.id) {
        data.id = row.id;
        data.master_set_price_id = this.goodInfo.id;
        url = "/user/masterSetPriceSize/updatePrice";
      } else {
        url = "/user/masterSetPriceSize/insertPrice";
        data.master_set_price_id = this.goodInfo.id;
      }
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          // this.updatePriceDialog = false
          this.getPriceList(this.goodInfo.id);
          this.getgoodLsit();
          this.closeEdit();
        }
      });
    },
    closeEdit() {
      this.index = null;
      this.form = {};
    },

    deleteItem(row, status) {
      let url = "/user/masterSetPriceSize/update";
      this.$axios
        .post(url, {
          id: row.id,
          status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (status == 5) {
              this.$message({
                type: "warning",
                message: "冻结成功!",
              });
            } else if (status == 2) {
              this.$message({
                type: "warning",
                message: "启用成功!",
              });
            }

            this.getPriceList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    //结束修改价格

    getCateChilds(cateName) {
      let a = this.categories_child_list.find((item) => {
        return cateName == item.name;
      });
      console.log(a);
      if (a) {
        return a.childList;
      } else [];
    },
    search() {
      this.currentPage = 1;
      this.getgoodLsit();
    },
    getCover(v) {
      this.form.face_url = v;
    },
    // mechanism_name(id) {
    //   let a = this.mechanism_name_list.find((item, index, arr) => {
    //     return item.id == id
    //   })
    //   if (a) {
    //     return a.mechanism_name
    //   } else return {}
    // },

    getProductImg(v) {
      // console.log(v);
      this.form.introduction_url = v;
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 1,
            source: "商城",
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;

          // this.typeTotal = res.data.data.total;
        });
    },
    // 商品列表分页获取
    getgoodLsit() {
      this.isLoading = true;
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: this.status ? this.status : null,
            type: "商城",
            // categories: '',
            // source: '商城',
            pageSize: 10,
            // is_school: false,
            currentPage: this.currentPage,
            title: this.title ? this.title : null,
            // master_id: this.master_id? this.master_id:null,
            // mechanism_id: 6,
          },
        })
        .then((res) => {
          this.goodList = res.data.data.rows;
          this.goodTotal = res.data.data.total;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getgoodLsit();
    },
    addNewgood() {
      let url = "/user/masterSetPrice/insertShop";
      this.if_clear = false;
      // console.log(this.form);
      let data = { ...this.form };
      data.type = "商城";
      data.status = 2;
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.addDialog = false;
          this.if_clear = true;
          this.getgoodLsit();
          this.if_clear = true;
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    openUpdate(row) {
      this.temp = row;
      this.$set(this.form, "is_discount", row.is_discount);
      this.$set(this.form, "is_recommend", row.is_recommend);
      this.updateDialog = true;
    },
    update() {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.form.id = this.temp.id;
      this.$axios
        .post(url, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.form = {
              categories_child: null,
              face_url: null,
              type: "商城",
              premium_point: null,
              status: 2,
              introduction_content: null,
              title: null,
            };
            this.getgoodLsit();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    frozen(row) {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.$axios
        .post(url, {
          id: row.id,
          status: 5,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getgoodLsit();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    unfrozen(row) {
      let url = "/user/masterSetPrice/update";
      this.if_clear = false;
      this.$axios
        .post(url, {
          id: row.id,
          status: 2,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "修改成功!" });
            this.updateDialog = false;
            this.getgoodLsit();
            this.if_clear = true;
          } else {
            this.$message({ type: "error", message: res.data.message });
          }
        });
    },
    // getCardLevel() {
    //   let url = '/user/userMemberLevel/queryTreeList'
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         pageSize: 99,
    //         platform: '体育宝',
    //         status: 1,
    //       },
    //     })
    //     .then((res) => {
    //       this.cardLevel = res.data.data
    //     })
    // },
    // getCardType(v) {
    //   let s = ''
    //   for (let i = 0; i < v.length; i++) {
    //     s += v[i][v[i].length - 1] + (i == v.length - 1 ? '' : ',')
    //   }
    //   this.form.card_type = s
    //   console.log(this.form)
    // },
    getAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 99,
            currentPage: 1,
          },
        })
        .then((res) => {
          this.age_type_list = res.data.data.rows;
        });
    },
    getSchoolAgeType() {
      let url = "/user/userServiceAge/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            pageSize: 99,
            currentPage: 1,
            platform: "学龄",
          },
        })
        .then((res) => {
          this.school_age_list = res.data.data.rows;
        });
    },
    // getgoodType() {
    //   let url = "/user/masterSetPrice/queryYesterdayCount";
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         status: 2,
    //         source: "体育宝",
    //         categories: "体育运动",
    //       },
    //     })
    //     .then((res) => {
    //       let temp = [];
    //       for (let item in res.data.data.totalMap) {
    //         // console.log(item);
    //         if (item != "total") {
    //           temp.push({
    //             name: item,
    //             value: res.data.data.totalMap[item],
    //           });
    //         }
    //       }
    //       this.$refs.chart.drawRing({
    //         total: res.data.data.totalMap.total,
    //         data: temp,
    //       });
    //     });
    // },
    // 机构查询远程查询
    // searchOrgName(query) {
    //   let url = '/user/mastermechanism/queryMechanismByType'
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         // type: 'teach_paypal',
    //         categories: '体育运动',
    //         source: '体育宝',
    //         mechanism_name: typeof query == 'string' && query != '' ? query : null,
    //         pageSize: 99,
    //         currentPage: 1,
    //       },
    //     })
    //     .then((res) => {
    //       // console.log(res);
    //       this.mechanism_name_list = res.data.data
    //     })
    // },
    // searchMasterName(query) {
    //   let url = "/user/masterInfo/queryByMessage";
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         type: "teach_paypal",
    //         full_name: query,
    //         pageSize: 10,
    //         currentPage: 1,
    //         status: 2,
    //       },
    //     })
    //     .then((res) => {
    //       this.master_name_list = res.data.data.rows;
    //     });
    // },
    // 商品详情页
    toGoodDetails(row) {
      this.$router.push({
        name: "couDetails",
        query: {
          type: "mec",
          couID: row.id,
          couMec: row.map.masterMechanismEntity.mechanism_name,
        },
      });
    },
    // levelName(id) {
    //   let a = this.allCardLevel.find((item, index, arr) => {
    //     return item.id == id
    //   })
    //   if (a) {
    //     return a
    //   } else return {}
    // },
    // getAllCardLevel() {
    //   let url = '/user/userMemberLevel/queryManagerListPage'
    //   this.$axios
    //     .get(url, {
    //       params: {
    //         pageSize: 99,
    //         platform: '体育宝',
    //         status: 1,
    //       },
    //     })
    //     .then((res) => {
    //       this.allCardLevel = res.data.data.rows
    //     })
    // },
    handleClose(done) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addDialog = false;
          this.updateDialog = false;
          this.if_clear = true;
          this.updateInfoDialog = false;
          this.updatePriceDialog = false;
          this.temp = {};
          this.form = {};
          this.price_list = [];
          done();
        })
        .catch((_) => {});
    },
  },
  mounted() {
    this.ks = window.screen.width < 768;
    this.getgoodLsit();
    // this.searchOrgName()
    this.getCateList();
    this.getAgeType();
    this.getSchoolAgeType();
    // this.getAllCardLevel()
  },
};
</script>

<style lang="less">
.el-table thead tr th {
  background-color: #eaeaea !important;
}
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
